<template>

  <div>

    <role-add-new
      :is-add-new-role-sidebar-active.sync="isAddNewRoleSidebarActive"
      :form-data-edit="formDataEdit"
      :form-disabled="formDisabled"
      @fetch-roles="fetchRoles"
      ref="roleAddNewForm"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="3" class="">
            <label>Mostrar</label>
            <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>registros</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="9">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" class="btn-icon mr-1" @click="createRole">
                <feather-icon icon="PlusIcon" /> Crear
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      
      <b-table
        ref="refRoleListTable"
        class="position-relative"
        :items="dataTable"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="tableSettings.sortBy"
        show-empty
        empty-text="No se encontraron datos"
        :sort-desc.sync="tableSettings.sortDesc"
      >
  
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
  
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="detailRole(data.item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Detalle</span>
            </b-dropdown-item>
  
            <b-dropdown-item @click="editRole(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
  
            <b-dropdown-item @click="deleteRole(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
  
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
  
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
  
            <b-pagination
              v-model="tableSettings.page"
              :total-rows="totalRows"
              :per-page="tableSettings.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
  
          </b-col>
  
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import RoleAddNew from './RoleAddNew.vue'

export default {
  name: 'Role',
  components: {
    RoleAddNew,
    vSelect,
  },
  data() {
    return {
      formDisabled: false,
      isAddNewRoleSidebarActive: false,
      refRoleListTable: null,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      inputs: [
        { value: 'id', title: 'Id' },
        { value: 'name', title: 'Rol' },
      ],
      typeSearch: [
        { value: 'LIKE', title: 'Igual' },
        { value: 'NOT LIKE', title: 'No es igual' },
        { value: '>', title: 'Mayor que' },
        { value: '<', title: 'Menor que' }
      ],
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Rol', sortable: true },
        { key: 'description', label: 'Descripción', sortable: true },
        { key: 'countPermissions', label: 'Permisos asignados'},
        { key: 'actions', label: 'acciones' },
      ],
      sortBy: 'id',
      isSortDirDesc: true,
      totalRows: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      dataTable: [],
      showLoadingTable: false,
      tableSettings: {
        filter: this.$route.params && this.$route.params.id,
        searchQuery: '',
        input: '',
        typeSearch: '',
        perPage: 10,
        page: 1,
        sortBy: 'id',
        sortDesc: true,
      },
      formDataEdit: {},
    }
  },
  watch: {
    "tableSettings.sortBy": {
      handler(val) {
        this.fetchRoles()
      },
    },
    "tableSettings.sortDesc": {
      handler(val) {
        this.fetchRoles()
      },
    },
    "tableSettings.perPage": {
      handler(val) {
        this.fetchRoles()
      },
    },
    "tableSettings.searchQuery": {
      handler(val) {
        this.fetchRoles()
      },
    },
    "tableSettings.page": {
      handler(val) {
        this.fetchRoles()
      },
    },
  },
  created() {
    this.fetchRoles()
  },
  methods: {
    searchData () {
      console.log("No hace nada")
    },
    fetchRoles() {
      this.showLoadingTable = true
      this.$http.get('/roles/list', { params: this.tableSettings }).then((response) => {
        this.dataTable = response.data.roles
        this.totalRows = response.data.total
        this.dataMetaCounter()
        this.showLoadingTable = false
      })
    },
    dataMetaCounter() {
      const localItemsCount = this.dataTable.length
      this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
      this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
      this.dataMeta.of = this.totalRows
    },
    detailRole(role) {
      this.$refs.roleAddNewForm.newForm = false;
      this.formDisabled = true
      this.$refs.roleAddNewForm.formData = role
      this.isAddNewRoleSidebarActive = true
    },
    createRole() {
      this.$refs.roleAddNewForm.newForm = true;
      this.$refs.roleAddNewForm.resetForm();
      this.formDisabled = false
      this.isAddNewRoleSidebarActive = true
    },
    editRole(role) {
      this.formDisabled = false
      this.$refs.roleAddNewForm.formData = role
      this.$refs.roleAddNewForm.newForm = false;
      this.isAddNewRoleSidebarActive = true
    },
    deleteRole(role) {
      this.$swal({
        title: `Esta séguro de eliminar el Rol ${role.name}?`,
        text: "Este proceso no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post('/roles/delete/' + role.id).then(() => {
            this.fetchRoles()
            this.$swal({
              icon: 'success',
              title: 'Rol eliminado',
              text: 'El rol ha sido eliminado.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Eliminación cancelada',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
